<!--
 * @Author: zhai
 * @Description: 完成
-->
<template>
    <div class="done-box">
        <!-- <p>根据您填写的资料，您的初步评分如下</p>
        <div class="total-score">
            <span class="score">{{ currentItem.score_sum }}</span>分
        </div>
        <p class="score-des">
            80分以上符合申请条件，文案老师会根据您的资料确认最终得分情况
        </p>
        <p class="categories">评分项</p>
        <el-table :data="currentItem.score_detail" border style="width: 100%" center>
            <el-table-column prop="age" label="年龄" align="center" />
            <el-table-column prop="education_background" label="学历" align="center" />
            <el-table-column prop="work_experience" label="工作经验" align="center" />
            <el-table-column prop="international_work_experience" label="国际工作经验" align="center" />
            <el-table-column prop="talent_listing" label="人才清单" align="center" />
            <el-table-column prop="language" label="语言" align="center" />
            <el-table-column prop="family" label="家庭" align="center" />
        </el-table> -->
        <div class="done-tips">
            已提交的信息可通过【基础信息-个人信息表】查看
        </div>
    </div>
</template>

<script lang="javascript">
import { postClientInfo } from '../../../../api/gifted'
export default {
    name: "Done",
    components: {},
    data() {
        return {
        };
    },
    props: {
        currentItem: {
            type: Object,
            default: () => {
                return {};
            },
        },
        loading: {
            type: Boolean,
            default: false
        },
    },
    created() {
        console.log(this.currentItem)
    },
    methods: {
        // 完成并提交
        checkInfo() {
            this.currentItem.next = 0;
            this.$emit('update:loading', true)
            postClientInfo(this.currentItem).then((res) => {
                if (res.code === 200) {
                    // 切换步骤保存 下一步的时候才设置
                    this.$message.success("提交成功！");
                    this.$emit('next')
                }
            }).finally(() => {
                this.$emit('update:loading', false)
            });
        }
    },
};
</script>

<style lang="less" scoped>
.done-box {
    width: 960px;
    margin: 108px auto 0;
    font-size: 14px;
    color: #303133;

    > p {
        margin-bottom: 16px;
    }
}

.total-score {
    width: 100%;
    text-align: center;
    span.score {
        display: inline-block;
        color: #f56c6c;
        font-size: 24px;
        font-weight: bold;
        margin-right: 3px;
    }
}

.score-des {
    text-align: center;
    color: #606266;
    margin-bottom: 24px;
}

.categories {
    font-weight: bold;
    font-size: 16px;
}

.done-tips {
    margin-top: 16px;
    color: #606266;
}
</style>
