<!--
 * @Author: zhai
 * @Description: 工作经验
-->
<template>
  <div>
    <el-form ref="userForm" :model="userForm" :rules="rules" label-width="140px">
      <!-- 主申请人工作经历 -->
      <div class="card-container">
        <div v-for="(domain, idx) in userForm.works" :key="idx" class="work-box">
          <Container :is-fillet="idx === 0" :title="idx === 0 ? '主申请人工作经历（从最近一份全职工作开始，直至毕业后的第一份工作）' : '工作经验' + (idx + 1)
            ">
            <!-- <div v-if="idx !== 0" class="workr-heard">
                            <el-popconfirm placement="top" title="确定要删除此工作经历吗？" @confirm="removeMember(domain)">
                                <el-button slot="reference">删除</el-button>
                            </el-popconfirm>
                        </div> -->
            <div slot="handle" v-if="idx > 0">
              <el-popconfirm placement="top" title="确定删除？" @confirm="removeMember(domain)">
                <el-button slot="reference" type="primary">删除</el-button>
              </el-popconfirm>
            </div>
            <el-row class="block-top">
              <!-- 左 -->
              <el-col :span="12">
                <!-- <el-tooltip placement="top">
                                    <div slot="content">须与推荐信或离职证明上所盖公<br />章的单位名称完全相同</div>
                                    <img class="sigh-img" style="left: 38px;bottom: 27px;" src="@/assets/img/hover.png" alt="">
                                </el-tooltip> -->

                <el-form-item label="用人单位:" :prop="'works.' + idx + '.company_name'" :rules="[
                  {
                    required: true,
                    message: '请输入用人单位',
                    trigger: 'blur',
                  },
                ]">
                  <el-autocomplete ref="autocomplete" class="full-width" v-model="domain.company_name"
                    :fetch-suggestions="querySearchAsync" :debounce="300" :trigger-on-focus="false" clearable
                    placeholder="与工作证明的盖章用人单位（全程）一致" @select="companyNameSelect($event, domain)">
                    <template slot-scope="{ item }">
                      <div v-if="item.empty" class="select-empty">暂未检索到相关企业，请准确、完整输入您的企业名称</div>
                      <div v-else v-html="keyWordsHighLight(item.value, triggerAssociate)"></div>
                    </template>
                  </el-autocomplete>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="用人单位性质:" :prop="'works.' + idx + '.industry'" :rules="[
                  {
                    required: true,
                    message: '请选择用人单位性质',
                    trigger: 'change',
                  },
                ]">
                  <el-select v-model="domain.industry" class="full-width" placeholder="请选择" clearable>
                    <el-option v-for="(item, index) in subsidiaryOrganList" :key="index" :label="item" :value="item" />
                  </el-select>
                </el-form-item>
                <!-- <div v-if="domain.industry.indexOf('其他')!=-1" class="one-input" style="margin-top:-16px;">
                                    <el-form-item label="" :prop="'works.'+idx+'.industrys'" :rules="[{required: true,message: '请输入用人单位性质',trigger: 'blur'}]">
                                        <el-input v-model.trim="domain.industrys" maxlength="30" class="full-width" style="margin-top:16px" placeholder="请备注" clearable />
                                    </el-form-item>
                                </div> -->
              </el-col>
            </el-row>
            <el-row class="block-top">
              <!-- 左 -->
              <el-col :span="12">
                <el-form-item label="用人单位介绍:" class="company-con" :prop="'works.' + idx + '.company_introduce'" :rules="[
                  {
                    required: false,
                    message: '请输入用人单位介绍',
                    trigger: 'blur',
                  },
                ]">
                  <el-tooltip class="introduce-tip" effect="dark" content="可先填写用人单位名称，选择指定的用人单位后，系统将为您自动填写单位介绍"
                    placement="top-start">
                    <i class="el-icon-warning-outline" style="color: #666; font-size: 16px;"></i>
                  </el-tooltip>
                  <el-input v-model="domain.company_introduce" :rows="4" style="width: 300px;" placeholder="如方便提供，请填写"
                    type="textarea" maxlength="2000" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="用人单位官网:" class="company-con" :prop="'works.' + idx + '.company_website'" :rules="[
                  {
                    required: false,
                    message: '请输入用人单位官网',
                    trigger: 'blur',
                  },
                ]">
                  <el-tooltip class="introduce-tip" effect="dark" content="可先填写用人单位名称，选择指定的用人单位后，系统将为您自动填写官网"
                    placement="top-start">
                    <i class="el-icon-warning-outline" style="color: #666; font-size: 16px;"></i>
                  </el-tooltip>
                  <el-input v-model="domain.company_website" :rows="4" style="width: 300px;" placeholder="如有，请输入官网链接"
                    type="textarea" spellcheck="false" maxlength="200" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <!-- <el-col :span="12">
                                <el-form-item label="工作地点:" :prop="'works.'+idx+'.location_aboard'" :rules="[{required: true, message: '请选择工作地点',trigger: 'change'}]">
                                    <div class="flex-center">
                                        <el-radio-group v-model="domain.location_aboard" style="margin-top: 9px;" @change="changeLocationAboard($event,domain)">
                                            <el-radio :label="0">中国</el-radio>
                                            <el-radio :label="1">国外</el-radio>
                                        </el-radio-group>
                                        <el-form-item v-if="domain.location_aboard === 0" :key="1" label="" :prop="'works.'+idx+'.location.area'" class="margin-zero" :rules="[{required: true,message: '请选择城市',trigger: 'change'}]">
                                            <CitySelect v-model="domain.location.area" class="city-inline" style="width: 154px;" placeholder="请选择城市" />
                                        </el-form-item>
                                        <el-form-item v-if="domain.location_aboard === 1" :key="2" label="" :prop="'works.'+idx+'.location.foreign'" class="margin-zero" :rules="[{required: true,message: '请输入城市',trigger: 'blur'}]">
                                            <div class="city-input-width city-inline">
                                                <el-input v-model.trim="domain.location.foreign" placeholder="请输入城市" maxlength="20" clearable />
                                            </div>
                                        </el-form-item>
                                    </div>
                                </el-form-item>
                            </el-col> -->
              <el-col :span="12">
                <div style="display: flex">
                  <el-form-item label="工作地点:" :rules="{
                    required: true,
                    message: '请输入国家及地区',
                    trigger: 'blur',
                  }" :prop="'works.' + idx + '.location.country'" style="width: 300px">
                    <CountrySelect v-model="domain.location.country" placeholder="输入匹配国家及地区" :index="idx"
                      @input="handleSelect" style="width: 163px" />
                  </el-form-item>
                  <el-form-item v-if="domain.location.country.indexOf('中国') !== -1 || domain.location.country === ''"
                    :prop="'works.' + idx + '.location.area'" :rules="{
                      required: true,
                      message: '请选择城市',
                      trigger: 'change',
                    }" label-width="0">
                    <CitySelect :country="domain.location.country" v-model="domain.location.area" style="width: 140px" />
                  </el-form-item>
                  <el-form-item v-else :prop="'works.' + idx + '.location.foreign'" :rules="{
                    required: true,
                    message: '请输入城市',
                    trigger: 'blur',
                  }" label-width="0">
                    <el-input v-model="domain.location.foreign" clearable placeholder="请输入城市" style="width: 140px" />
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="12">
                <el-form-item label="职位:" :prop="'works.' + idx + '.position'"
                  :rules="[{ required: true, message: '请输入职位', trigger: 'blur' }]">
                  <el-input v-model.trim="domain.position" class="full-width" maxlength="200" placeholder="请输入"
                    clearable />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="入职年月:" :prop="'works.' + idx + '.entry_time'" :rules="[
                  {
                    required: true,
                    message: '请选择入职年月',
                    trigger: 'change',
                  },
                ]">
                  <el-date-picker v-model="domain.entry_time" class="full-width" value-format="yyyy-MM"
                    :picker-options="pickerBeginDateBefore" type="month" placeholder="选择入职时间"
                    @change="(value) => pikerStartTime(value, idx)" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="离职年月:" :prop="'works.' + idx + '.departure_time'" :rules="[
                  {
                    required: !domain.departure_time_checked,
                    message: '请选择离职年月',
                    trigger: 'change',
                  },
                ]">
                  <el-date-picker v-if="!domain.departure_time_checked" v-model="domain.departure_time"
                    value-format="yyyy-MM" :picker-options="pickerBeginDateBefore" class="input-width" type="month"
                    placeholder="选择离职时间" @change="(value) => pikerEndTime(value, idx)" />
                  <el-checkbox v-model="domain.departure_time_checked" style="margin-top: -16px" label="至今"
                    @change="endTimeNow($event, domain)">至今</el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="工作证明文件:" :prop="'works.' + idx + '.work_certificate'" :rules="[
                  {
                    required: true,
                    message: '请选择工作证明文件',
                    trigger: 'change',
                  },
                ]">
                  <el-select v-model="domain.work_certificate" class="full-width" placeholder="请选择工作证明文件" clearable>
                    <el-option v-for="(item, index) in workCertificateList" :key="index" :label="item" :value="item" />
                  </el-select>
                </el-form-item>
                <el-form-item class="check-experience">
                  <el-checkbox v-model="domain.international_work_experience" :true-label="1"
                    :false-label="0">相关工作属国际工作经验</el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="职责性质:" :prop="'works.' + idx + '.duty'" :rules="[
                  {
                    required: true,
                    message: '请输入职责性质',
                    trigger: 'blur',
                  },
                ]">
                  <el-input v-model.trim="domain.duty" class="full-width" placeholder="简单概况您的工作内容（20字以内）" maxlength="20"
                    clearable />
                </el-form-item>
              </el-col>
            </el-row>
            <el-divider class="divider"></el-divider>
            <el-row>
              <el-col :span="12">
                <el-form-item label="企业人数规模:" :prop="'works.' + idx + '.company_member_total'" :rules="[
                  {
                    required: true,
                    message: '请输入企业人数规模',
                    trigger: 'blur',
                  },
                ]">
                  <!--  @change="isExecutives(domain, idx)" -->
                  <el-input v-model.trim="domain.company_member_total" oninput="value=value.replace(/[^\d]/g,'')"
                    maxlength="8" class="full-width" placeholder="请输入企业人数规模" clearable
                    @blur="domain.company_member_total = $event.target.value" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="行政架构层级:">
                  <el-tooltip effect="dark" placement="top">
                    <div slot="content">
                      <p>请参考公司的组织架构，如总经理</p>
                      <p>为最顶级，则总经理往下的层级则</p>
                      <p>为第二层级</p>
                    </div>
                    <el-select v-model="domain.administrative_level" class="full-width" placeholder="请选择行政架构层级" clearable>
                      <el-option v-for="(item, index) in hierarchyList" :key="index" :label="item.tit"
                        :value="item.idx" />
                    </el-select>
                  </el-tooltip>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="下属管理人数:" :prop="'works.' + idx + '.company_manage_total'" :rules="[
                    {
                      required: true,
                      message: '请输入下属管理人数',
                      trigger: 'blur',
                    },
                  ]">
                  <!-- @change="isExecutives(domain, idx)" -->
                  <el-input v-model.trim="domain.company_manage_total" oninput="value=value.replace(/[^\d]/g,'')"
                    maxlength="8" class="full-width" placeholder="请输入下属管理人数" clearable
                    @blur="domain.company_manage_total = $event.target.value" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <span slot="label" class="label-title">公司营业额/澳元（近一年）:</span>
                  <!-- @change="isExecutives(domain, idx)" -->
                  <el-input v-model.trim="domain.turnover" class="full-width" placeholder="请输入具体数字" clearable
                    @input="(value) => (domain.turnover = value.replace(/^(0+)|[^\d]+/g, ''))" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="职位水平类别:" :prop="'works.' + idx + '.professional_level'" :rules="[
                  {
                    required: true,
                    message: '请选择职位水平类别',
                    trigger: 'change',
                  },
                ]">
                  <el-select v-model="domain.professional_level" class="full-width" placeholder="请选择职位水平类别" clearable>
                    <!--  v-show="!domain.is_executives && proitem.idx !== 1" -->
                    <el-option v-for="(proitem, proindex) in postList" :id="proindex" :key="proitem.idx"
                      :label="proitem.tit" :value="proitem.idx" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <span slot="label" class="label-title">公司业务性质/范畴/所属行业:</span>
                  <el-input v-model.trim="domain.business_nature" maxlength="100" class="full-width"
                    placeholder="请输入公司业务性质/范畴/所属行业" clearable />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item v-if="domain.professional_level === 1" label="高管证明文件:"
                  :prop="'works.' + idx + '.executives_certificate'" :rules="[
                    {
                      required: true,
                      message: '请选择职位水平类别',
                      trigger: 'change',
                    },
                  ]">
                  <el-select v-model="domain.executives_certificate" class="full-width" placeholder="请选择职位水平类别">
                    <el-option v-for="(item, index) in executivesCertificateList" :key="index" :label="item"
                      :value="item" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="domain.professional_level === 1">
              <el-col :span="24">
                <div style="display: flex">
                  <!-- <el-form-item v-if="domain.professional_level === 1" label="高管在职时间:" :prop="'works.'+idx+'.top_manager_date'" :rules="[{required: true,message: '请输入高管在职时间',trigger: 'blur'}]">
                                        <el-date-picker
                                            style="width: 240px"
                                            v-model="domain.top_manager_date"
                                            type="monthrange"
                                            value-format="yyyy-MM"
                                            range-separator="至"
                                            start-placeholder="开始月份"
                                            end-placeholder="结束月份">
                                        </el-date-picker>
                                    </el-form-item>
                                    <span v-if="domain.professional_level === 1" class="manager-date">{{ (domain.top_manager_date && domain.top_manager_date[1] !== '') ? $dayjs(domain.top_manager_date[1]).diff(domain.top_manager_date[0], 'month') : 0}}个月</span> -->
                  <el-col :span="10">
                    <el-form-item label="高管在职开始时间:" :prop="'works.' + idx + '.top_manager_date_start'" :rules="[
                      {
                        required: true,
                        message: '请选择高管在职开始时间',
                        trigger: 'change',
                      },
                    ]">
                      <el-date-picker v-model="domain.top_manager_date_start" value-format="yyyy-MM"
                        :picker-options="pickerBeginDateBefore" type="month" placeholder="请选择高管在职开始时间"
                        @change="(value) => topManagerStartTime(value, idx)" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="14" style="display: flex">
                    <el-form-item label="高管在职结束时间:" :prop="'works.' + idx + '.top_manager_date_end'" :rules="[
                      {
                        required: !domain.top_manager_date_checked,
                        message: '请选择高管在职结束时间',
                        trigger: 'change',
                      },
                    ]">
                      <el-date-picker v-if="!domain.top_manager_date_checked" v-model="domain.top_manager_date_end"
                        value-format="yyyy-MM" :picker-options="pickerBeginDateBefore" type="month"
                        placeholder="请选择高管在职结束时间" @change="(value) => topManagerEndTime(value, idx)" />
                      <el-checkbox v-model="domain.top_manager_date_checked" style="margin-top: -16px; margin-left: 5px"
                        label="至今" @change="tMendTimeNow($event, domain)">至今</el-checkbox>
                    </el-form-item>
                    <span class="manager-date">{{
                      domain.top_manager_date_long
                      | dateLong(
                        domain.top_manager_date_start,
                        domain.top_manager_date_end,
                        domain.top_manager_date_checked,
                        that
                      )
                    }}个月</span>
                  </el-col>
                </div>
              </el-col>
            </el-row>
            <el-divider class="divider"></el-divider>
            <el-row class="block-top">
              <el-col :span="23">
                <p class="explain" @click="explainVisible = true">
                  为了让客户您更好及更清晰的了解以下内容的填写，请点击下我，我将为您做个说明
                </p>
              </el-col>
            </el-row>
            <el-row class="block-top rel">
              <el-col :span="23">
                <!-- <el-popover placement="top-start" width="500" trigger="hover">
                  <i slot="reference" class="el-icon-question icon-query"></i>
                  <span slot="default" v-html="wduty" class="icon-query-color">
                  </span>
                </el-popover> -->
                <el-form-item label="工作职责:" :prop="'works.' + idx + '.wduty'" :rules="[
                  {
                    required: true,
                    message: '请输入工作职责',
                    trigger: 'blur',
                  },
                ]">
                  <el-input v-model="domain.wduty" :rows="15" :placeholder="wduty" type="textarea" maxlength="2000"
                    show-word-limit />
                  <!-- <Tinymce
                    :ids="'wduty' + idx"
                    :height="300"
                    v-model="domain.wduty"
                    :placeholderHtml="wduty"
                  ></Tinymce> -->
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="block-top rel">
              <!-- <el-popover placement="top-start" width="500" trigger="hover">
                <i slot="reference" class="el-icon-question icon-query"></i>
                <span slot="default" v-html="wduty" class="icon-query-color">
                </span>
              </el-popover> -->
              <el-col :span="23">
                <el-form-item label="工作亮点:" :prop="'works.' + idx + '.highlights'" :rules="[
                  {
                    required: true,
                    message: '请输入工作亮点',
                    trigger: 'blur',
                  },
                ]">
                  <el-input v-model="domain.highlights" :rows="15" :placeholder="highlights" type="textarea"
                    maxlength="2000" show-word-limit />
                  <!-- <Tinymce
                    :ids="'highlights' + idx"
                    :height="300"
                    v-model="domain.highlights"
                    :placeholderHtml="highlights"
                  ></Tinymce> -->
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item>
                  <el-checkbox v-model="domain.is_project_introduction" :true-label="1" :false-label="0">需要按每段项目详细介绍</el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>
            <div v-if="domain.is_project_introduction === 1">
              <el-row v-if="domain.projects">
                <el-col :span="23">
                  <el-form-item v-for="(pitem, pindex) in domain.projects" :key="pindex"
                    :label="'项目介绍' + (pindex + 1) + '(选填)：'">
                    <div class="projectc">
                      <div @click="delPro(domain.projects, pindex)" class="del-pro">删除</div>
                      <el-row class="block-top">
                        <el-col :span="24">
                          <el-form-item label="项目名称:" label-width="90px"
                            :prop="'works.' + idx + '.projects.' + pindex + '.project_name'" :rules="[
                              {
                                required: true,
                                message: '请输入项目名称',
                                trigger: 'blur',
                              },
                            ]">
                            <el-input v-model="pitem.project_name" :rows="4" placeholder="如方便提供，请填写" maxlength="20" />
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row class="block-top">
                        <el-col>
                          <el-form-item label="项目时间:" label-width="90px"
                            :prop="'works.' + idx + '.projects.' + pindex + '.project_time'" :rules="[
                              {
                                required: true,
                                message: '请选择项目时间',
                                trigger: 'change',
                              },
                            ]">
                            <el-date-picker v-model="pitem.project_time" value-format="yyyy-MM-dd" type="daterange"
                              range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 620px;" />
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row class="block-top">
                        <el-col :span="24">
                          <el-form-item label="项目业绩/亮点:" label-width="90px"
                            :prop="'works.' + idx + '.projects.' + pindex + '.project_highlights'" :rules="[
                              {
                                required: true,
                                message: '请输入项目业绩/亮点',
                                trigger: 'blur',
                              },
                            ]">
                            <el-input v-model="pitem.project_highlights" :rows="10" :placeholder="projectcHPlace"
                              type="textarea" maxlength="1000" show-word-limit />
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item v-if="domain.projects && domain.projects.length > 0" label="">
                    <el-button @click="addproject(domain)" type="primary">新增项目</el-button>
                  </el-form-item>
                  <el-form-item v-else label="项目介绍1(选填)：">
                    <el-button @click="addproject(domain)" type="primary">新增项目</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <el-row class="block-top jsfile">
              <el-col :span="23">
                <el-form-item label="简历/工作总结:" class="resumeFile">
                  <UploadFile :is-upload="!!domain.not_need_upload_file" :file-names="fileNames" :multiple="false"
                    :idx="idx" :file-list="domain.file" :is-show-file="true" file-list-name="fileName"
                    file-list-url="fileUrl" :limit="100" :file-type="[]"
                    :format-tip="['jpg', 'png', 'txt', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'pdf']"
                    @fileList="onChangeFile" />
                  <div class="not-con">
                    <el-checkbox v-if="idx !== 0" v-model="domain.not_need_upload_file" :true-label="1"
                      :false-label="0">与上份工作的“简历/工作总结”一致</el-checkbox>
                    <div class="resumeFile-msg">( 可上传多个文件，支持word、ppt、excel、图片等文件 )</div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-divider v-if="idx !== userForm.works.length -1" class="divid"></el-divider> -->
            <div v-if="idx === userForm.works.length - 1" class="add-box">
              <div class="add-btn" @click="addMember">
                <el-button type="text">+新增工作经验</el-button>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </el-form>
    <el-dialog :visible.sync="explainVisible" width="490px" :before-close="explainClose" top="5%">
      <div class="explain-img" />
    </el-dialog>
  </div>
</template>

<script>
import CitySelect from "@/components/CitySelect/CitySelect";
import Tinymce from "@/components/Tinymce/Tinymce";
import { postClientInfo, postIsExecutives, getCompanys } from "../../../../api/gifted";
export default {
  name: "Work",
  components: {
    CitySelect,
    Tinymce,
  },
  filters: {
    dateLong: (long, start, end, check, that) => {
      if (check && start) {
        let td = that.$dayjs(new Date()).format("YYYY-MM-DD");
        return that.$dayjs(td).diff(start, "month");
      } else if (!check && start && end) {
        return that.$dayjs(end).diff(start, "month");
      } else {
        return 0;
      }
    },
  },
  props: {
    currentItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 只能选择当天之前的日期
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() >= Date.now() - 8.64e6;
        },
      },
      postList: [
        { tit: "半专业人士", idx: 5, isShow: true },
        { tit: "专业人士", idx: 4, isShow: true },
        { tit: "初级经理人", idx: 3, isShow: true },
        { tit: "中级经理人", idx: 2, isShow: true },
        { tit: "高级经理人", idx: 1, isShow: true },
        { tit: "其他", idx: 6, isShow: true },
      ], //职位水平
      hierarchyList: [
        { tit: "组织架构最顶级", idx: 1 },
        { tit: "组织架构第二层级", idx: 2 },
        { tit: "组织架构第三层级", idx: 3 },
        { tit: "组织架构第四层级", idx: 4 },
        { tit: "以上都不是", idx: 10 },
      ], //行政架构层级
      subsidiaryOrganList: [
        "有限责任公司",
        "股份有限公司",
        "跨国有限责任公司",
        "跨国股份有限公司",
        "上市公司",
        "上市跨国公司",
        "教育机构",
        "半公营企业",
        "政府机构",
        "跨国合伙企业",
        "跨国个人独资企业",
        "合伙企业",
        "个人独资企业",
        "国家/政府间组织",
        "志愿/慈善/宗教团体",
        "其他（请备注）",
      ],
      // 工作证明文件
      workCertificateList: [
        "盖章并签字的推荐信 (证明效果最强)",
        "签字的推荐信+盖章的工作证明",
        "仅有签字的推荐信",
        "仅有社保记录/劳动合同/工牌等",
        "仅提供声明书解释",
        "仅工作证明",
        // "不能提供以上任何官方证明文件"
      ],
      // 高管证明文件
      executivesCertificateList: [
        "盖章且签字的组织架构图和审计报告",
        // "仅签字的组织架构图",
        // "其他高级管理证明文件",
      ],
      wduty: `示例: 在职期间负责的核心工作职责,建议不要超过3点。如果就职过多个职位，请分段描述;
            例子:
            模式1、在司担任某某岗位，任职期间，主要负责xxxxx工作；在此期间，组建了xxxx，实现了xxxx；
            模式2、在担任xxx岗位期间，主要负责xxx工作，具体的工作职责包括如下；
            2.1）xxxxxxx；
            2.2）xxxxxxx；
            2.3）xxxxxxx；`, //  ps: 注意修改这个文案时把提交校验的判断字符串一起修改
      highlights: `示例: 打破常规，超越同行或其他同事的优秀业绩；或有开创性且突破公司期望值的杰出贡献，且给公司带来价值和收益。
            请举例2-3个工作亮点：
            a. 是什么：某项目背景或工作目标或难点
            b. 为什么：为什么会出现这个项目工作／难点，旨在体现分析过程，为您后面提出解决方案做铺垫
            c. 怎么处理：您是怎么把这件事情做好的？用了什么技术/方法？有何突出/创新之处？
            d. 有什么价值：为公司、客户或您本身带来了哪些价值？客户或您本身带来了哪些价值？
            最好用实际数据证明，比如销售业绩增加了多少，或者利润增加了多少，客户群体增加了多少等。`, //  ps: 注意修改这个文案时把提交校验的判断字符串一起修改
      recIndx: null, // 记录点的是哪条数据的上传
      explainVisible: false, // 说明
      fileNames: "",
      rules: {},
      that: this,
      triggerAssociate: "",
      projectcHPlace: `在职期间负责的核心项目，如果负责过多个项目，请分段描述：

例子：
模式1、本人对XX项目展开深入研究，通过对XX方法/方式，研究出XX。此外，她还指出XX
问题，反映了XX。XX的研究成果对XX产生了重要性，为XX领域/方面提供参考依据；

式2、本人在司参与了XX项目，该项目具有XX突出意义。在此项目中，XX是个难点，本人通过XX方法，实现了XX成果。在XX方面，本人通过XX方式，完成了XX工作。本人凭借在XX领域的丰富经验，推动项目在中国/海外市场取得XX成果。`,
      companysData: []
    };
  },
  created() {
    this.userForm = this.currentItem;
    // this.fileNames = JSON.parse(localStorage.getItem("user")).orderId + '' || '';
    this.userForm.works?.forEach((item) => {
      item.executives_certificate =
        item.professional_level === 1 && item.executives_certificate ? "盖章且签字的组织架构图和审计报告" : "";
    });
  },
  methods: {
    // 禁止入职年月乱选
    pikerStartTime(e, idx) {
      console.log(this.userForm.works[idx]);
      let entry_time = e;
      let departure_time = this.userForm.works[idx].departure_time;
      if (departure_time && !this.userForm.works[idx].departure_time_checked) {
        let result = new Date(departure_time).getTime() > new Date(entry_time).getTime();
        if (!result) {
          this.userForm.works[idx].entry_time = "";
          this.$message.error("入职年月不得在离职年月之后");
        }
      }
    },
    // 禁止入职年月乱选
    pikerEndTime(e, idx) {
      let entry_time = this.userForm.works[idx].entry_time;
      let departure_time = e;
      if (entry_time && !this.userForm.works[idx].departure_time_checked) {
        let result = new Date(entry_time).getTime() < new Date(departure_time).getTime();
        if (!result) {
          this.userForm.works[idx].departure_time = "";
          this.$message.error("离职年月不得在入职年月之前");
        }
      }
    },
    // 离职至今选择
    endTimeNow(e, item) {
      if (e) {
        item.departure_time = "";
      } else {
        if (item.departure_time === "至今") {
          item.departure_time = "";
        }
      }
    },
    // 禁止高管年月乱选
    topManagerStartTime(e, idx) {
      console.log(this.userForm.works[idx]);
      let start_time = e;
      let end_time = this.userForm.works[idx].top_manager_date_end;
      if (end_time && !this.userForm.works[idx].top_manager_date_checked) {
        let result = new Date(end_time).getTime() > new Date(start_time).getTime();
        if (!result) {
          this.userForm.works[idx].top_manager_date_start = "";
          this.$message.error("高管在职年月不得在离职年月之后");
        }
      }
    },
    // 禁止入职年月乱选
    topManagerEndTime(e, idx) {
      let start_time = this.userForm.works[idx].top_manager_date_start;
      let end_time = e;
      if (start_time && !this.userForm.works[idx].top_manager_date_checked) {
        let result = new Date(start_time).getTime() < new Date(end_time).getTime();
        if (!result) {
          this.userForm.works[idx].top_manager_date_end = "";
          this.$message.error("离职年月不得在入职年月之前");
        }
      }
    },
    // 高管至今选择
    tMendTimeNow(e, item) {
      console.log(e);
      console.log(item);
      if (e) {
        item.top_manager_date_end = "";
      } else {
        if (item.top_manager_date_end === "至今") {
          item.top_manager_date_end = "";
        }
      }
    },
    // 更改工作地点
    changeLocationAboard(e, item) {
      if (e === 0) {
        item.location.foreign = "";
      } else {
        item.location.area = [];
      }
    },
    // 新增工作经验
    addMember() {
      this.userForm.works.push({
        //工作经历
        id: "",
        company_name: "", //雇主名称
        duty: "", //职责性质
        entry_time: "", //入职时间
        departure_time: "", //离职时间
        industry: "", //雇主所属机构性质
        location: {
          //工作地点
          area: [],
          foreign: "",
          country: "",
        },
        location_aboard: "", //工作地点国内国外  0-国内 1-国外
        professional_level: "", //职位水平(1.高层管理岗2.中层管理岗3.低层管理岗4.专业人士5.半专业人士6.其他)
        professionalOther: "",
        work_certificate: "", //工作证明文件
        is_executives: 0,
        company_member_total: 0, //企业人数规模
        administrative_level: "", //行政架构层级
        company_manage_total: 0, //下属管理人数
        top_manager_date_checked: false, // 高管至今
        top_manager_date_start: "", // 高管开始时间
        top_manager_date_end: "", // 高管结束时间
        departure_time_checked: false, // 是否选中至今
        executives_certificate: "", //高管证明文件
        position: "", //职位
        remark: "", //备注
        wduty: null,
        highlights: null,
        file: [],
        key: Date.now(),
        not_need_upload_file: 0, // 是否与上份工作的“简历/工作总结”一致
        match_listing: 0,
        turnover: 0,
        projects: [],
        company_introduce: "", // 公司介绍
        company_website: "", // 公司网站
      });
    },
    // 移除工作经验
    removeMember(item) {
      var index = this.userForm.works.indexOf(item);
      console.log(this.userForm.works.indexOf(item));
      if (index !== -1) {
        this.userForm.works.splice(index, 1);
        this.userForm.works[0].not_need_upload_file = 0;
      }
    },
    // 上传列表变化
    onChangeFile(list, idx) {
      console.log(idx);
      console.log(list);
      this.userForm.works[idx].file = list;
    },
    explainClose() {
      this.explainVisible = false;
    },
    // 是否是高管
    isExecutives(item, idx) {
      console.log(item);
      // if (Number(item.company_member_total) < 1 ) {
      //     return this.$message.error("企业人数规模不能小于1人");
      // }
      if (item.company_member_total !== "" && item.turnover !== "" && item.company_manage_total !== "") {
        let param = {
          company_member_total: Number(item.company_member_total),
          turnover: Number(item.turnover),
          company_manage_total: Number(item.company_manage_total),
        };
        postIsExecutives(param).then((res) => {
          if (res.code === 200) {
            // is_executive 是否是高管  res.data  1是0否
            if (res.data === 1) {
              item.is_executives = res.data;
              item.professional_level = 1;
              item.executives_certificate = "盖章且签字的组织架构图和审计报告";
            } else if (res.data === 0) {
              item.is_executives = res.data;
              item.professional_level = "";
              item.executives_certificate = "";
            }
          }
        });
      }
    },
    /**
     * @description 校验当前步骤的表单
     * @param {type} 1保存 2下一步
     */
    checkInfo(type) {
      if (type === 1) {
        this.hanledSaveQuestion(type);
      } else {
        this.$refs.userForm.validate((valid) => {
          if (valid) {
            for (let i = 0; i < this.userForm.works.length; i++) {
              if (this.userForm.works[i].file.length === 0 && this.userForm.works[i].not_need_upload_file === 0) {
                return this.$message.error(`请上传第${i + 1}份简历/工作总结`);
              }
              if (
                Number(this.userForm.works[i].company_member_total) <
                Number(this.userForm.works[i].company_manage_total)
              ) {
                return this.$message.error("下属管理人数不应大于企业人数");
              }
            }
            return this.hanledSaveQuestion(type);
          } else {
            this.$utils.scrollIntoError(this);
          }
        });
      }
    },
    async hanledSaveQuestion(parmas) {
      this.$emit("update:loading", true);
      this.userForm.next = parmas - 1;
      try {
        const { code } = await postClientInfo(this.userForm);
        if (code === 200) {
          if (parmas === 1) {
            this.$message.success("保存成功！");
            this.$emit("update");
          } else {
            this.$message.success("提交成功！");
            this.$emit("next");
          }
        }
      } finally {
        this.$emit("update:loading", false);
      }
    },
    // 输入匹配
    handleSelect(v, index) {
      console.log(v.indexOf("中国") !== -1);
      if (v.indexOf("中国") !== -1) {
        this.userForm.works[index].location_aboard = 0;
      } else {
        this.userForm.works[index].location_aboard = 1;
      }
      this.userForm.works[index].location.area = [];
      this.userForm.works[index].location.foreign = "";
    },

    // 企业名称搜索建议
    querySearchAsync(queryString, cb) {
      if (queryString.length >= 2) {
        const pattern = /[\u4e00-\u9fa5]/;
        const chineseContain = pattern.test(queryString.trim());
        if (chineseContain || (!chineseContain && queryString.length >= 4)) {
          this.$refs.autocomplete[0].activated = true;
          this.triggerAssociate = queryString;
          getCompanys({ keyword: queryString.trim(), limit: 20, page: 1 }).then((res) => {
            if (res.code === 200 && res.data.length > 0) {
              this.companysData = res.data
              const searchResult = res.data.map((item) => {
                return { value: item.name };
              });
              cb(searchResult);
            } else {
              cb([{ empty: true }]);
            }
          });
        } else {
          this.$refs.autocomplete[0].activated = false;
        }
      } else {
        this.$refs.autocomplete[0].activated = false;
      }
    },

    // 关键词高亮
    keyWordsHighLight(source, keyword) {
      const pattern = new RegExp(keyword, "gi");
      return source.replace(pattern, `<span style="color: #4388FF">$&</span>`);
    },
    // 用人单位选择
    companyNameSelect(events, domain) {
      const result = this.companysData.find(item => item.name === events.value);
      domain.company_introduce = result.introduce
      domain.company_website = result.website_link
    },
    // 删除项目
    delPro(projects, pindex) {
      projects.splice(pindex, 1);
    },
    // 新增项目
    addproject(item) {
      console.log(item);
      item.projects.push({
        project_name: "",
        project_time: [],
        project_highlights: "",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.select-empty {
  font-size: 13px;
  color: #606266;
  white-space: break-spaces;
  line-height: 20px;
  margin-top: 16px;
}

.sigh-img {
  position: absolute;
}

.city-inline {
  display: inline-block;
  width: 150px;
}

.input-width {
  width: 232px;
  margin-right: 10px;
}

.full-width {
  width: 300px;
}

.work-box {

  // border-bottom: 1px solid #dcdfe6;
  // padding-bottom: 14px;
  // padding-top: 15px;
  .workr-heard {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 12px 16px 8px 16px;
  }
}

.work-box:first-child {
  padding-top: 0;
}

.flex-center {
  display: flex;
  align-items: flex-start;
}

.margin-zero {
  margin-bottom: 0;
}

.add-people {
  width: 928px;
  height: 35px;
  line-height: 35px;
  border: 1px solid #dcdfe6;
  color: #409eff;
  font-size: 14px;
  text-align: center;
  margin: 16px auto;
  cursor: pointer;
}

.experience-box {
  padding: 22px 0 0 16px;
  font-size: 14px;
  line-height: 20px;
  color: #606266;

  .experience-title {
    color: #303133;
    margin-bottom: 10px;
    position: relative;
    padding-left: 12px;

    &::before {
      content: "*";
      position: absolute;
      left: 0;
      top: 2px;
      color: red;
    }
  }

  .experience-des {
    color: #e6a23c;
    margin-bottom: 18px;

    a {
      color: #409eff;
      margin-left: 10px;
    }
  }

  .experience-radio {
    margin-left: 0;

    .el-radio {
      display: block;
      margin-bottom: 12px;
    }
  }

  .experience-radio-four {
    .el-radio {
      width: 22%;
      margin-bottom: 12px;
    }
  }
}

.resumeFile {
  position: relative;
  display: flex;

  &::before {
    content: "*";
    position: absolute;
    left: 22px;
    top: 8px;
    color: red;
  }

  .resumeFile-msg {
    color: red;
    display: inline-block;
    width: 400px;
    margin-left: 20px;
  }
}

.explain {
  padding-left: 70px;
  padding-bottom: 5px;
  font-size: 14px;
  color: #02a7f0;
  cursor: pointer;
}

.explain-img {
  width: 100%;
  height: 690px;
  background: url("https://upload.cdn.galaxy-immi.com/public/img/crm/explain.jpg?x-oss-process=image/resize,w_450,m_lfit") no-repeat center;
}

.jsfile {
  /deep/ .el-form-item__content {
    margin-left: 0px !important;
  }
}

.not-con {
  position: absolute;
  left: 90px;
  top: 0px;
  display: flex;
}

.interval {
  height: 100px;
}

.manager-date {
  margin-left: 10px;
  color: #666;
  padding-top: 5px;
  font-size: 14px;
}

.divider {
  background-color: #eff2fa;
}

.add-box {
  background: white;
  padding: 20px;
  text-align: center;

  // border: 1px solid #dcdfe6;
  // border-top: none;
  .add-btn {
    border: 1px dashed #ddd;
    padding: 3px 0;
  }
}

.divid {
  margin-bottom: 0;
}

.rel {
  position: relative;

  .icon-query {
    position: absolute;
    top: 9px;
    left: 45px;
    color: #848181;
  }

  .icon-query-color {
    color: #666 !important;
  }
}

.label-title {
  display: inline-block;
  line-height: 20px;
}

.check-experience {
  /deep/ .el-form-item__content {
    margin-left: 35px !important;
  }
}

.projectc {
  width: 100%;
  background: #fafafa;
  padding: 20px 20px 20px 0;
  position: relative;
  border-radius: 8px;

  // margin-bottom: 12px;
  .del-pro {
    position: absolute;
    left: -50px;
    top: 30px;
    color: red;
    cursor: pointer;
  }
}

.company-con {
  position: relative;

  .introduce-tip {
    position: absolute;
    top: 30px;
    left: -30px;
  }
}
</style>
