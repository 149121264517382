import { render, staticRenderFns } from "./Children.vue?vue&type=template&id=2905fd62&scoped=true&"
import script from "./Children.vue?vue&type=script&lang=js&"
export * from "./Children.vue?vue&type=script&lang=js&"
import style0 from "./Children.vue?vue&type=style&index=0&id=2905fd62&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2905fd62",
  null
  
)

export default component.exports