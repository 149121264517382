<template>
    <div class="parental-info">
        <Container class="mt-20" v-if="ruleForm.length === 0" title="兄弟姐妹信息" style="height:45px;"></Container>
        <!-- 兄弟姐妹信息 -->
        <Container
            :title="'兄弟姐妹信息'+(i+1)"
            :is-fillet="i === 0"
            :class="[i === 0?'mt-20':'']"
            v-for="(item, i) in ruleForm" :key="i"
        >
            <div slot="handle">
                <el-popconfirm placement="top" title="确定删除？" @confirm="del(i)">
                    <el-button slot="reference" size="mini">删除</el-button>
                </el-popconfirm>
            </div>
            <el-form :model="ruleForm[i]" :ref="'ruleForm'+i" label-width="180px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item
                            label="姓名："
                            prop="subset_name"
                            :rules="{ required: true, message: '请输入姓名', trigger: 'blur' }"
                        >
                            <el-input v-model="item.subset_name" clearable maxlength="50" placeholder="请输入对应的内容" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="婚姻状况：" prop="married" :rules="{ required: true, message: '请选择婚姻状况', trigger: 'change' }">
                            <el-select v-model="item.married" placeholder="请选择" clearable style="width: 100%;">
                                <el-option v-for="(item, index) in $CONFIG.MARRIED" :key="index" :label="item.label" :value="item.value+''" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            label="出生日期："
                            prop="birthday"
                            :rules="{ required: true, message: '请选择出生日期', trigger: 'change' }"
                        >
                            <el-date-picker
                                style="width: 100%;"
                                v-model="item.birthday"
                                type="date"
                                value-format="yyyy-MM-dd"
                                :picker-options="pickerBeginDateBefore"
                                placeholder="年 -月 -日"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-row>
                            <el-col :span="17">
                                <el-form-item
                                    label="出生城市："
                                    prop="birth_place_aboard"
                                    :rules="{ required: true, message: '请选择出生城市', trigger: 'change' }"
                                >
                                    <el-radio-group v-model="item.birth_place_aboard" @change="handleSelect($event, i)">
                                        <el-radio :label="0">国内</el-radio>
                                        <el-radio :label="1">国外</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                            <el-col :span="7" v-if="item.birth_place_aboard === 0">
                                <el-form-item
                                    class="form-item"
                                    prop="birth_place.area"
                                    :rules="{ required: true, message: '请选择出生城市', trigger: 'change' }"
                                >
                                    <CitySelect v-model="item.birth_place.area"  style="width: 100%;" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="7" v-if="item.birth_place_aboard === 1">
                                <el-form-item
                                    class="form-item"
                                    prop="birth_place.foreign"
                                    :rules="{ required: true, message: '请输入城市', trigger: 'blur' }"
                                >
                                    <el-input v-model="item.birth_place.foreign" clearable maxlength="100" placeholder="请输入城市" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            label="性别："
                            prop="sex"
                            :rules="{ required: true, message: '请选择性别', trigger: 'change' }"
                        >
                            <el-select v-model="item.sex" placeholder="请选择性别" clearable style="width: 100%">
                                <!-- <el-option label="未知" :value="0" /> -->
                                <el-option label="男" :value="1" />
                                <el-option label="女" :value="2" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            label="职业："
                            prop="occupation"
                            :rules="{ required: true, message: '请选择职业', trigger: 'change' }"
                        >
                            <el-select v-model="item.occupation" placeholder="请选择" style="width: 100%;">
                                <el-option
                                    v-for="(item, i) in workOpts"
                                    :key="i"
                                    :label="item"
                                    :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            label="现居住国家/地区："
                            prop="live_country"
                            :rules="{ required: true, message: '请填写现居住国家/地区', trigger: 'change' }"
                        >
                            <CountrySelect v-model="item.live_country" placeholder="输入匹配国家" style="width: 100%" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item
                                    label="是否办理过澳门身份证："
                                    prop="child_has_hk_id.has"
                                    :rules="{ required: true, message: '请选择是否办理过澳门身份证', trigger: 'change' }"
                                >
                                    <el-radio-group v-model="item.child_has_hk_id.has" @change="handleChang($event, i)">
                                        <el-radio label="Y">是</el-radio>
                                        <el-radio label="N">否</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item
                                    v-if="item.child_has_hk_id.has === 'Y'"
                                    label="澳门身份证号码："
                                    prop="hk_identity_card"
                                    :rules="[{ required: true, message: '请输入澳门身份证号码', trigger: 'blur' },
                                    { required: true, trigger: 'blur', validator: $rules.validate.macaoId }]">
                                    <el-input v-model.trim="item.hk_identity_card" clearable maxlength="20" placeholder="请输入澳门身份证号码" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </el-form>
        </Container>
        <div class="add-box">
            <div class="add-btn" @click="addMember">
                <el-button type="text">+新增成员</el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            // 只能选择当天之前的日期
            pickerBeginDateBefore: {
                disabledDate(time) {
                    return time.getTime() >= Date.now() - 8.64e6;
                },
            },
            ruleForm: [],
            workOpts: [
                "企业管理者（高级管理者） ",
                "企业管理者（包括初、中级层管理者）",
                "自主创业（有实际运营的公司）",
                "普通职员（办公室/写字楼）",
                "专业人员（医生/教师/律师/记者/文体）",
                "个体经营者/承包商",
                "政府机关干部/公务人员",
                "在校学生",
                "家庭主妇",
                "退休人员",
                "应届毕业生",
                "其他"
            ]
        }
    },
    created() {
        if (this.data.length > 0) {
            this.data.forEach(item => {
                for(let key in item) {
                    if (item[key] == 100) {
                        item[key] = ''
                    }
                }
            })
            this.ruleForm = this.data
        }
    },
    methods: {
        // 校验参数
        checkParm() {
            const ruleFormArray = []
            this.ruleForm.forEach((item, i) => {
                ruleFormArray.push(this.$refs['ruleForm'+i][0].validate())
            })
            const isCheck = Promise.all(ruleFormArray)
            return isCheck
        },
        // 新增成员
        addMember() {
            this.ruleForm.push({
                relation: 5,
                subset_name: '',
                used_name: '',
                birthday: '',
                sex: '',
                birth_place_aboard: 0,
                birth_place: {
                    details: '',
                    country: '',
                    area: [],
                    foreign: ''
                },
                occupation: '',
                occupation_name: '',
                child_has_hk_id: {
                    has: ''
                },
                hk_identity_card: '',
                country_option: ''
            })
        },
        // 删除成员
        del(index) {
            this.ruleForm.splice(index, 1)
        },
         // 是否办理过澳门身份证
        handleChang(v, index) {
            this.ruleForm[index].hk_identity_card = ''
        },
        // 出生城市选择
        handleSelect(v, index) {
            if (v===0) {
                this.ruleForm[index].birth_place.foreign = ''
            } else {
                this.ruleForm[index].birth_place.area = []
            }
        }
    },
}
</script>

<style lang="less" scoped>
.mt-20 {
    margin-top: 20px;
}
.form-item {
    /deep/ .el-form-item__content {
        margin-left: 0 !important;
    }
}
.warp {
    border-top: 1px solid #eee;
    padding-top: 20px;
}
.add-box {
    background: white;
    padding: 20px;
    text-align: center;
    border: 1px solid #dcdfe6;
    border-top: 0 none;
    .add-btn {
        border: 1px dashed #ddd;
        padding: 3px 0;
        cursor: pointer;
    }
}
</style>
