<template>
    <div class="family">
        <!-- 配偶信息 -->
        <Spouse
            ref="spouse"
            v-if="hasSpouse"
            :data="currentItem.spouse"
            :married="currentItem.information.married"
        />
        <!-- 子女 -->
        <Children ref="children" :data="currentItem.child"></Children>
        <!-- 父母 -->
        <ParentalInfo ref="parentalInfo" :data-father="currentItem.father" :data-mother="currentItem.mother"></ParentalInfo>
        <!-- 配偶父母 -->
        <SpouseParentalInfo v-if="hasSpouse" ref="spouseParentalInfo" :data-father="currentItem.spouse_father" :data-mother="currentItem.spouse_mother"></SpouseParentalInfo>
        <!-- 兄弟姐妹 -->
        <BrothersAndSisters ref="brothersAndSisters" :data="currentItem.brother"></BrothersAndSisters>
    </div>
</template>

<script>
import Spouse from './C/Spouse.vue'
import Children from './C/Children.vue'
import ParentalInfo from './C/ParentalInfo.vue'
import SpouseParentalInfo from './C/SpouseParentalInfo.vue'
import BrothersAndSisters from './C/BrothersAndSisters.vue'
import { postClientInfo } from '../../../../api/gifted'
export default {
    props: {
        currentItem: {
            type: Object,
            default:() => {}
        },
        loading: {
            type: Boolean,
            default: false
        },
    },
    components: {
        Spouse,
        Children,
        ParentalInfo,
        SpouseParentalInfo,
        BrothersAndSisters
    },
    data() {
        return {
            parentalInfo: []
        }
    },
    created() {
        console.log(this.currentItem, this.$CONFIG)
    },
    computed: {
        // 是否有配偶
        hasSpouse() {
            const married = this.currentItem.information.married;
            const options = this.$CONFIG.MARITAL_STATUS_OPTIONS;
            return married != options.single.value && married != options.divorced.value;
        }
    },
    methods: {
        // 保存和下一步
        async saveProgressBar(type) {
            try {
                this.$emit('update:loading', true)
                const { code } = await postClientInfo(this.currentItem)
                if (code === 200) {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    })
                    if (type === 0) {
                        this.$emit('update')
                    } else {
                        this.$emit('next')
                    }
                }
            } finally {
                this.$emit('update:loading', false)
            }
        },
        /**
         * @description 校验当前步骤的表单
         * @param {type} 1保存 2下一步
         */
        checkInfo(type) {
            if (this.hasSpouse) {
                this.currentItem.spouse = this.$refs.spouse.ruleForm
                // 新增选择随行后，自动添加受养人声明
                if(this.currentItem.spouse.follow == 1) {
                    this.currentItem.spouse.statement = 1
                }

                this.currentItem.spouse_father = this.$refs.spouseParentalInfo.ruleForm[0]
                this.currentItem.spouse_mother = this.$refs.spouseParentalInfo.ruleForm[1]

            } else {
                this.currentItem.spouse = null
            }
            this.currentItem.child = this.$refs.children.ruleForm
            this.currentItem.father = this.$refs.parentalInfo.ruleForm[0]
            this.currentItem.mother = this.$refs.parentalInfo.ruleForm[1]
            this.currentItem.brother = this.$refs.brothersAndSisters.ruleForm

            // // 新增选择随行后，自动添加受养人声明
            if(this.currentItem.child.length) {
                this.currentItem.child.forEach(item => {
                    if(item.follow == 1) {
                        item.statement = 1
                    }
                })
            }
            if(type === 1) {
                // 保存时不做校验
                // ajax请求

                this.currentItem.next = type - 1
                this.saveProgressBar(this.currentItem.next)
                return
            }

            // 点击的是下一步 校验成功后发送请求
            Promise.all([
                this.hasSpouse && this.$refs.spouse.checkParm(),
                this.$refs.children.checkParm(),
                this.$refs.parentalInfo.checkParm(),
                this.hasSpouse && this.$refs.spouseParentalInfo.checkParm(),
                this.$refs.brothersAndSisters.checkParm()
            ])
            .then(async () => {
                this.currentItem.next = type - 1
                this.saveProgressBar(this.currentItem.next)
                // 请求成功后 跳转
                // this.$emit('next')
            })
            .catch((err) => {
                console.log(err)
                setTimeout(() => {
                    this.$utils.scrollIntoError(this)
                }, 100)
            })
        },
    },
}
</script>

<style lang="less" scoped>
/deep/ .el-form-item {
    margin-left: 0;
}
/deep/ .el-radio {
    margin-right: 15px;
}
</style>
