<template>
  <div class="education">
    <div v-for="(item, i) in ruleForm" :key="i">
      <Container :is-fillet="i === 0" :title="i === 0 ? '主申请人学历（请从大专开始写起）' : '学历' + (i + 1)">
        <div slot="handle" v-if="i > 0">
          <el-popconfirm placement="top" title="确定删除？" @confirm="del(i)">
            <el-button slot="reference" type="primary">删除</el-button>
          </el-popconfirm>
        </div>
        <div class="form">
          <el-form :model="ruleForm[i]" :ref="'ruleForm' + i" label-width="110px">
            <el-row>
              <el-col :span="12">
                <el-form-item label="学校全称：" :rules="{
                  required: true,
                  message: '请输入学校全称',
                  trigger: 'change',
                }" prop="college">
                  <SchoolSelect v-model="item.college" placeholder="如是世界百强，则输入匹配选择" style="width: 100%"
                    @blur="handleBlur(i, 'college')" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="授课形式：" :rules="{
                  required: true,
                  message: '请选择授课形式',
                  trigger: 'change',
                }" prop="type">
                  <el-select v-model="item.type" placeholder="请选择" style="width: 100%">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="入学年月：" :rules="{
                  required: true,
                  message: '请选择毕业年月',
                  trigger: 'change',
                }" prop="admission_time">
                  <el-date-picker style="width: 100%" v-model="item.admission_time" type="month" value-format="yyyy-MM"
                    :picker-options="item.pickerAdmissionTime" placeholder="入学年月"
                    @focus="handleGraduateChange(item.graduate_time, i)">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="毕业年月：" :rules="{
                  required: true,
                  message: '请选择毕业年月',
                  trigger: 'change',
                }" prop="graduate_time">
                  <el-date-picker style="width: 100%" v-model="item.graduate_time" type="month" value-format="yyyy-MM"
                    :picker-options="item.pickerGraduateTime" placeholder="毕业年月"
                    @focus="handleAdmissionChange(item.admission_time, i)">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="专业：" :rules="{
                  required: true,
                  message: '请输入专业',
                  trigger: 'blur',
                }" prop="professional">
                  <el-input v-model="item.professional" clearable maxlength="50" placeholder="专业" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-row>
                  <el-col :span="13">
                    <el-form-item label="学位：" :rules="{
                      required: true,
                      message: '请选择学位',
                      trigger: 'change',
                    }" prop="background">
                      <el-select v-model="item.background" placeholder="请选择" @change="handleSelectChange($event, i)">
                        <el-option v-for="item in degreeOpts" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="11" class="form-item">
                    <el-form-item :rules="{
                      required: true,
                      message: '请选择学位类型',
                      trigger: 'change',
                    }" prop="degree_type">
                      <!-- <el-tooltip effect="dark" placement="top">
                                                <div slot="content">
                                                    <p>该内容在学位证上，通常是授予两字后面，</p>
                                                    <p>如：授予“理学”学士学位，则填理学</p>
                                                </div>
                                                <el-input v-model.trim="item.degree_type" clearable maxlength="50" placeholder="学位类型，如：管理学" />
                                            </el-tooltip> -->
                      <el-select v-model="item.degree_type" placeholder="请选择学位类型" @change="handleSelectChange($event, i)">
                        <el-option v-for="item in degreeTypes" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-row>
                  <el-col :span="15">
                    <el-form-item label="上课地点：" :rules="{
                      required: true,
                      message: '请输入上课地点',
                      trigger: 'blur',
                    }" prop="school_address.country">
                      <el-tooltip effect="dark" content="请填写上课所在的校区，对应的国家及城市" placement="top">
                        <CountrySelect v-model="item.school_address.country" placeholder="输入匹配国家及地区"
                          @input="handleSelect($event, i)" />
                      </el-tooltip>
                    </el-form-item>
                  </el-col>
                  <el-col :span="9">
                    <el-form-item class="form-item" v-if="item.school_address.country.indexOf('中国') !== -1 ||
                      item.school_address.country === ''
                      " prop="school_address.area" :rules="{
    required: true,
    message: '请选择城市',
    trigger: 'change',
  }">
                      <CitySelect :country="item.school_address.country" v-model="item.school_address.area"
                        style="width: 100%" />
                    </el-form-item>
                    <el-form-item class="form-item" prop="school_address.foreign" :rules="{
                      required: true,
                      message: '请输入城市',
                      trigger: 'blur',
                    }" v-else>
                      <el-input v-model="item.school_address.foreign" clearable placeholder="请输入城市" />
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="add-box" v-if="i === ruleForm.length - 1">
          <div class="add-btn" @click="addAducation">
            <el-button type="text">+新增学历信息</el-button>
          </div>
        </div>
      </Container>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // 只能选择当天之前的日期
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() >= Date.now() - 8.64e6;
        },
      },
      ruleForm: [
        {
          college: "",
          type: "",
          admission_time: "",
          graduate_time: "",
          professional: "",
          background: "",
          degree_type: "",
          school_address: {
            details: "",
            country: "",
            area: [],
            foreign: "",
          },
        },
      ],
      options: [
        { value: "全日制", label: "全日制" },
        { value: "兼读制", label: "兼读制" },
        { value: "全日制(遥距课程)", label: "全日制(遥距课程)" },
        { value: "兼读制(遥距课程)", label: "兼读制(遥距课程)" },
      ],
      degreeOpts: [
        { value: "无学位（专科）", label: "无学位（专科）" },
        { value: "无学位（本科）", label: "无学位（本科）" },
        { value: "无学位（硕士）", label: "无学位（硕士）" },
        { value: "无学位（博士）", label: "无学位（博士）" },
        { value: "学士学位", label: "学士学位" },
        { value: "硕士学位", label: "硕士学位" },
        { value: "博士学位", label: "博士学位" },
      ],
      degreeTypes: [
        { value: "会计/金融", label: "会计/金融" },
        { value: "艺术及人文/文学", label: "艺术及人文/文学" },
        { value: "工商管理", label: "工商管理" },
        { value: "经济学", label: "经济学" },
        { value: "工程学", label: "工程学" },
        {
          value: "资讯科技/电子及软件工程/数据科学",
          label: "资讯科技/电子及软件工程/数据科学",
        },
        { value: "法律", label: "法律" },
        { value: "生命科学及医学", label: "生命科学及医学" },
        { value: "管理学", label: "管理学" },
        { value: "理学", label: "理学" },
        { value: "社会科学", label: "社会科学" },
        { value: "其他", label: "其他" },
      ],
    };
  },
  created() {
    if (this.data.length > 0) {
      this.ruleForm = this.data;
      this.ruleForm.forEach((item, i) => {
        this.$set(this.ruleForm[i], "pickerAdmissionTime", {
          disabledDate(time) {
            return time.getTime() >= Date.now() - 8.64e6;
          },
        });
        this.$set(this.ruleForm[i], "pickerGraduateTime", {
          disabledDate(time) {
            const admission_time = item.admission_time;
            if (admission_time) {
              const date = this.$dayjs(admission_time).valueOf();
              return time.getTime() <= date;
            }
          },
        });
      });
    }
  },
  methods: {
    // 新增学历
    addAducation() {
      this.ruleForm.push({
        college: "",
        type: "",
        admission_time: "",
        graduate_time: "",
        professional: "",
        background: "",
        degree_type: "",
        school_address: {
          country: "",
          area: [],
          foreign: "",
        },
      });
    },
    // 校验参数
    checkForm() {
      const ruleFormArray = [];
      this.ruleForm.forEach((item, i) => {
        ruleFormArray.push(this.$refs["ruleForm" + i][0].validate());
      });
      const isCheck = Promise.all(ruleFormArray);
      return isCheck;
    },
    // 删除
    del(index) {
      this.ruleForm.splice(index, 1);
    },
    // 输入匹配
    handleSelect(v, index) {
      if (v.indexOf("中国") !== -1) {
        this.ruleForm[index].school_address_aboard = 0;
      } else {
        this.ruleForm[index].school_address_aboard = 1;
      }
      this.ruleForm[index].school_address.area = [];
      this.ruleForm[index].school_address.foreign = "";
    },
    // 学位清楚校验
    handleSelectChange(v, i) {
      if (v.includes("无学位")) {
        this.ruleForm[i].degree_type = "";
      }
      this.$refs["ruleForm" + i][0].clearValidate(["degree_type"]);
    },
    // 学校全称输入匹配
    async getSchoolList() { },
    // 毕业
    handleAdmissionChange(v, index) {
      if (v) {
        const date = this.$dayjs(v).valueOf();
        this.ruleForm[index].pickerGraduateTime = {
          disabledDate(time) {
            return time.getTime() <= date;
          },
        };
      }
      // else {
      //     this.ruleForm[index].pickerGraduateTime = {
      //         disabledDate(time) {
      //             return time.getTime() >= Date.now() - 8.64e6;
      //         }
      //     }
      // }
    },
    // 入学
    handleGraduateChange(v, index) {
      if (v) {
        const date = this.$dayjs(v).valueOf();
        this.ruleForm[index].pickerAdmissionTime = {
          disabledDate(time) {
            return time.getTime() > date;
          },
        };
      } else {
        this.ruleForm[index].pickerAdmissionTime = {
          disabledDate(time) {
            return time.getTime() >= Date.now() - 8.64e6;
          },
        };
      }
    },
    // 输入选择去除提示
    handleBlur(i, parm) {
      if (this.ruleForm[i][parm]) {
        const ruleForm = "ruleForm" + i;
        this.$refs[ruleForm][0].clearValidate([parm]);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.form {
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.add-box {
  margin: 20px 0;
  text-align: center;

  .add-btn {
    border: 1px dashed #ddd;
    padding: 3px 0;
    cursor: pointer;
  }
}

.form-item {
  /deep/ .el-form-item__content {
    margin-left: 0 !important;
  }
}
</style>
