import { render, staticRenderFns } from "./TheLord.vue?vue&type=template&id=56dfc232&scoped=true&"
import script from "./TheLord.vue?vue&type=script&lang=javascript&"
export * from "./TheLord.vue?vue&type=script&lang=javascript&"
import style0 from "./TheLord.vue?vue&type=style&index=0&id=56dfc232&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56dfc232",
  null
  
)

export default component.exports