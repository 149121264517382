<template>
    <div>
        <el-form :model="data" ref="ruleForm" label-width="180px">
            <el-row>
                <el-col :span="12">
                    <el-form-item
                        label="姓名："
                        prop="subset_name"
                        :rules="{ required: true, message: '请输入姓名', trigger: 'blur' }"
                    >
                        <el-input v-model="data.subset_name" clearable maxlength="50" placeholder="请输入对应的内容" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="曾用名：">
                        <el-input v-model="data.used_name" clearable maxlength="50" placeholder="请与户口本一致，若无则不填" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="婚前姓氏:" prop="surname">
                        <el-input
                            v-model="data.surname"
                            placeholder="如有则填写，仅支持输入字母"
                            clearable
                            auto-complete="off"
                            maxlength="20"
                            @input="checkSurname"
                            @blur="blurSurname"
                            />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="出生日期："
                        prop="birthday"
                        :rules="{ required: true, message: '请选择出生日期', trigger: 'change' }"
                    >
                        <el-date-picker
                            style="width: 100%;"
                            v-model="data.birthday"
                            value-format="yyyy-MM-dd"
                            type="date"
                            :picker-options="pickerOptions"
                            placeholder="年 -月 -日">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-row>
                        <el-col :span="17">
                            <el-form-item
                                label="出生城市："
                                prop="birth_place_aboard"
                                :rules="{ required: true, message: '请选择出生城市', trigger: 'change' }"
                            >
                                <el-radio-group v-model="data.birth_place_aboard" @change="handleSelect">
                                    <el-radio :label="0">国内</el-radio>
                                    <el-radio :label="1">国外</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="7" v-if="data.birth_place_aboard === 0">
                            <el-form-item
                                class="form-item"
                                prop="birth_place.area"
                                :rules="{ required: true, message: '请选择出生城市', trigger: 'change' }"
                            >
                                <CitySelect v-model="data.birth_place.area"  style="width: 100%;" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="7" v-if="data.birth_place_aboard === 1">
                            <el-form-item
                                class="form-item"
                                prop="birth_place.foreign"
                                :rules="{ required: true, message: '请输入城市', trigger: 'blur' }"
                            >
                                <el-input v-model="data.birth_place.foreign" clearable maxlength="100" placeholder="请输入城市" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="24">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item
                                label="职业："
                                prop="occupation"
                                :rules="{ required: true, message: '请选择职业', trigger: 'change' }"
                            >
                                <el-select v-model="data.occupation" clearable placeholder="请选择" style="width: 100%;" @change="handleOctChange">
                                    <el-option
                                        v-for="(item, i) in workOpts"
                                        :key="i"
                                        :label="item"
                                        :value="item">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                v-if="data.occupation === '在职'"
                                label="职业名称："
                                prop="occupation_name"
                                :rules="{ required: true, message: '请填写职业名称', trigger: 'blur' }"
                            >
                                <el-input v-model="data.occupation_name" clearable maxlength="20" placeholder="如在职，请填写目前的岗位名称" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
                <!-- 配偶-随行 start -->
                <el-col :span="24" v-if="data.follow === 1">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item
                                label="是否拥有学位："
                                prop="has_degree"
                                :rules="{ required: true, message: '请选择学位', trigger: 'change' }"
                            >
                                <el-tooltip effect="dark" content="若有学位，请填写所有学位，点击+号新增" placement="top">
                                    <el-select v-model="data.has_degree" clearable placeholder="请选择" style="width: 100%;">
                                        <el-option label="有学位" :value="1"></el-option>
                                        <el-option label="无学位" :value="0"></el-option>
                                    </el-select>
                                </el-tooltip>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24" v-if="data.has_degree == 1">
                            <el-row v-for="(item, i) in data.has_degree_json" :key="i" :gutter="10">
                                <el-col :span="12">
                                    <el-form-item
                                        label="最高学位："
                                        :prop="'has_degree_json.'+i+'.degree'"
                                        :rules="{ required: true, message: '请选择', trigger: 'change' }"
                                    >
                                        <el-select v-model="item.degree" placeholder="请选择" style="width: 100%;">
                                            <el-option label="学士学位" value="1"></el-option>
                                            <el-option label="硕士学位" value="2"></el-option>
                                            <el-option label="博士学位" value="3"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="4">
                                    <el-form-item
                                        class="form-item"
                                        :prop="'has_degree_json.'+i+'.address'"
                                        :rules="{ required: true, message: '请选择', trigger: 'change' }"
                                    >
                                        <el-select v-model="item.address" placeholder="请选择" style="width: 100%;">
                                            <el-option label="国内（颁发）" value="1"></el-option>
                                            <el-option label="国外（颁发）" value="2"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <!-- <el-col :span="4" class="add-degree">
                                    <span v-if="i===0 && data.has_degree_json.length < 5" @click="addDegree">+</span>
                                    <span v-if="i > 0" @click="delDegree(i)" style="padding: 3px 12px;">-</span>
                                </el-col> -->
                            </el-row>
                        </el-col>
                        <el-col :span="24">
                            <el-row>
                                <el-col :span="24">
                                    <el-form-item
                                        label="是否与主申请人同住："
                                        prop="nows"
                                        :rules="{ required: true, message: '请选择', trigger: 'change' }"
                                    >
                                        <el-radio-group v-model="data.nows">
                                            <el-radio :label="1">是</el-radio>
                                            <el-radio :label="0">否</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" v-if="data.nows == 0">
                                    <el-form-item
                                        label="现居住地址："
                                        prop="address"
                                        :rules="{ required: true, message: '请选择', trigger: 'change' }"
                                    >
                                        <CurrentAddressMacao ref="CurrentAddress" v-model="data.address" :is_live_oversea_year.sync="data.is_live_oversea_year"/>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="申请前十年是否曾居住其他国家/地区："
                                prop="is_area_ten_years"
                                :rules="{ required: true, message: '请选择', trigger: 'change' }"
                            >
                                <el-radio-group v-model="data.is_area_ten_years">
                                    <el-radio :label="1">是</el-radio>
                                    <el-radio :label="0">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24" v-if="data.is_area_ten_years == 1">
                        <el-row v-for="(item, i) in data.subset_residence" :key="i" :gutter="10">
                            <el-col :span="10">
                                <el-form-item
                                    :prop="'subset_residence.'+i+'.country'"
                                    :rules="{ required: true, message: '请选择' }"
                                >
                                    <span slot="label" v-if="i === 0">
                                        <span>居住信息：</span>
                                    </span>
                                    <CountrySelect v-model="item.country" style="width: 100%" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="4">
                                <el-form-item
                                    class="form-item"
                                    :prop="'subset_residence.'+i+'.city'"
                                    :rules="{ required: true, message: '请输入' }"
                                >
                                    <el-input v-model="item.city" clearable auto-complete="off" maxlength="50" placeholder="请输入城市" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="4">
                                <el-form-item
                                    class="form-item"
                                    :prop="'subset_residence.'+i+'.start_time'"
                                    :rules="{ required: true, message: '请选择' }"
                                >
                                    <el-date-picker
                                        style="width: 100%;"
                                        v-model="item.start_time"
                                        value-format="yyyy-MM-dd"
                                        type="date"
                                        placeholder="开始时间">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="4">
                                <el-form-item
                                    class="form-item"
                                    :prop="'subset_residence.'+i+'.end_time'"
                                    :rules="{ required: true, message: '请选择' }"
                                >
                                    <el-date-picker
                                        style="width: 100%;"
                                        v-model="item.end_time"
                                        value-format="yyyy-MM-dd"
                                        type="date"
                                        placeholder="结束时间">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="2" class="add-degree">
                                <span v-if="i===0" @click="addOtherLivingInfo">+</span>
                                <span v-if="i > 0" @click="delOtherLivingInfo(i)" style="padding: 3px 12px;">-</span>
                            </el-col>
                        </el-row>
                    </el-col>
                        <el-col :span="24">
                            <el-row>
                                <el-col :span="24">
                                    <el-form-item
                                        label="目前是否在澳："
                                        prop="child_in_hk.has"
                                        :rules="{ required: true, message: '请选择', trigger: 'change' }"
                                    >
                                        <el-radio-group v-model="data.child_in_hk.has">
                                            <el-radio label="Y">是</el-radio>
                                            <el-radio label="N">否</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" v-if="data.child_in_hk.has === 'Y'">
                                    <el-form-item
                                        label="获准逗留至："
                                        prop="child_in_hk.info"
                                        :rules="{ required: true, message: '请选择', trigger: 'change' }"
                                    >
                                        <el-date-picker
                                            style="width: 100%;"
                                            v-model="data.child_in_hk.info"
                                            value-format="yyyy-MM-dd"
                                            type="date"
                                            placeholder="年 -月 -日">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" v-if="data.child_in_hk.has === 'Y'">
                                    <el-row :gutter="10">
                                        <el-col :span="data.hk_identity === '其他' ? 14 : 24">
                                            <el-form-item
                                                label="在澳身份："
                                                prop="hk_identity"
                                                :rules="{ required: true, message: '请选择', trigger: 'change' }"
                                            >
                                                <el-select v-model="data.hk_identity" placeholder="请选择在澳身份" style="width: 100%;" @change="handleHkIdentityChange">
                                                    <el-option
                                                        v-for="(item, i) in hk_identity_list"
                                                        :key="i"
                                                        :label="item"
                                                        :value="item">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="10">
                                            <el-form-item
                                                v-if="data.hk_identity === '其他'"
                                                class="form-item"
                                                prop="hk_identity_other"
                                                :rules="{ required: true, message: '请输入在澳身份', trigger: 'blur' }"
                                            >
                                                <el-input v-model="data.hk_identity_other" clearable maxlength="20" placeholder="请输入对应的内容" />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-col>
                <!-- 配偶-随行 end -->
                <el-col :span="12">
                    <el-form-item
                        label="是否办理过澳门身份证："
                        prop="child_has_hk_id.has"
                        :rules="{ required: true, message: '请选择', trigger: 'change' }"
                    >
                        <el-radio-group v-model="data.child_has_hk_id.has" @change="handleChang">
                            <el-radio label="Y">是</el-radio>
                            <el-radio label="N">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        v-if="data.child_has_hk_id.has === 'Y'"
                        label="澳门身份证号码："
                        prop="hk_identity_card"
                        :rules="[{ required: true, message: '请输入澳门身份证号码', trigger: 'blur' },
                                { required: true, trigger: 'blur', validator: $rules.validate.macaoId }]">
                        <el-input v-model.trim="data.hk_identity_card" clearable maxlength="20" placeholder="请输入澳门身份证号码" />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },
            ruleForm: {},
            workOpts: ["在职", "退休", "无职业", "学生"],
            hk_identity_list: ['学生', '就业', '居留', '访问', '其他']
        }
    },
    created() {
        if (this.data.has_degree_json.length === 0) {
            this.data.has_degree_json.push({
                degree: '',
                address: ''
            })
        }
        if (this.data.subset_residence.length === 0) {
            this.data.subset_residence.push({
                country: '',
                city: '',
                start_time: undefined,
                end_time: undefined,
            })
        }
        this.$emit('update:data', this.data)
    },
    methods: {
        // 过滤婚前姓氏的字段
        checkSurname(value) {
            this.data.surname = value.replace(/[^\a-z.\s*\//]/ig, '').replace('/','').toUpperCase()
        },
        // 失焦后去除头尾冗余空格
        blurSurname() {
            this.data.surname = this.data.surname.trim()
        },
        // 校验
        checkParm() {
            const ruleFormArray = []
            ruleFormArray.push(this.$refs.ruleForm.validate())
            if (this.data.follow === 1 && this.data.nows == 0) {
                ruleFormArray.push(this.$refs.CurrentAddress.checkInfo())
            }
            const isCheck = Promise.all(ruleFormArray)
            return isCheck
        },
        // 是否办理过澳门身份证
        handleChang() {
            this.data.hk_identity_card = ''
            this.$emit('update:data', this.data)
        },
        // 出生城市选择
        handleSelect(v) {
            if (v===0) {
                this.data.birth_place.foreign = ''
            } else {
                this.data.birth_place.area = []
            }
            this.$emit('update:data', this.data)
        },
        // 是否在职
        handleOctChange(v) {
            if (v !== '在职') {
                this.data.occupation_name = ''
                this.$emit('update:data', this.data)
            }
        },
        // 添加学位
        addDegree() {
            this.data.has_degree_json.push({
                degree: '',
                address: ''
            })
            this.$emit('update:data', this.data)
        },
        // 删除学位
        delDegree(i) {
            this.data.has_degree_json.splice(i, 1)
            this.$emit('update:data', this.data)
        },
        // 新增近十年居住信息
        addOtherLivingInfo(){
            this.data.subset_residence.push({
                country: '',
                city: '',
                start_time: undefined,
                end_time: undefined,
            })
        },
        // 删除近十年居住信息
        delOtherLivingInfo(i) {
            this.data.subset_residence.splice(i, 1)
        },
        // 在澳身份
        handleHkIdentityChange(v) {
            if (v !== '其他') {
                // 解决select先选非‘其他’的option，后再选‘其他’时，出现的input输入失效问题
                delete this.data.hk_identity_other
            }
            this.$emit('update:data', this.data)
        }
    }
}
</script>

<style lang="less" scoped>
.form-item {
    /deep/ .el-form-item__content {
        margin-left: 0 !important;
    }
}
.add-degree {
    span {
        display: inline-block;
        border: 1px solid #aaa;
        padding: 2px 8px;
        border-radius: 20px;
        color: #aaa;
        font-weight: bold;
        font-size: 20px;
        cursor: pointer;
    }
}
</style>
