var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"education"},_vm._l((_vm.ruleForm),function(item,i){return _c('div',{key:i},[_c('Container',{attrs:{"is-fillet":i === 0,"title":i === 0 ? '主申请人学历（请从大专开始写起）' : '学历' + (i + 1)}},[(i > 0)?_c('div',{attrs:{"slot":"handle"},slot:"handle"},[_c('el-popconfirm',{attrs:{"placement":"top","title":"确定删除？"},on:{"confirm":function($event){return _vm.del(i)}}},[_c('el-button',{attrs:{"slot":"reference","type":"primary"},slot:"reference"},[_vm._v("删除")])],1)],1):_vm._e(),_c('div',{staticClass:"form"},[_c('el-form',{ref:'ruleForm' + i,refInFor:true,attrs:{"model":_vm.ruleForm[i],"label-width":"110px"}},[_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"学校全称：","rules":{
                required: true,
                message: '请输入学校全称',
                trigger: 'change',
              },"prop":"college"}},[_c('SchoolSelect',{staticStyle:{"width":"100%"},attrs:{"placeholder":"如是世界百强，则输入匹配选择"},on:{"blur":function($event){return _vm.handleBlur(i, 'college')}},model:{value:(item.college),callback:function ($$v) {_vm.$set(item, "college", $$v)},expression:"item.college"}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"授课形式：","rules":{
                required: true,
                message: '请选择授课形式',
                trigger: 'change',
              },"prop":"type"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择"},model:{value:(item.type),callback:function ($$v) {_vm.$set(item, "type", $$v)},expression:"item.type"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"入学年月：","rules":{
                required: true,
                message: '请选择毕业年月',
                trigger: 'change',
              },"prop":"admission_time"}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"month","value-format":"yyyy-MM","picker-options":item.pickerAdmissionTime,"placeholder":"入学年月"},on:{"focus":function($event){return _vm.handleGraduateChange(item.graduate_time, i)}},model:{value:(item.admission_time),callback:function ($$v) {_vm.$set(item, "admission_time", $$v)},expression:"item.admission_time"}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"毕业年月：","rules":{
                required: true,
                message: '请选择毕业年月',
                trigger: 'change',
              },"prop":"graduate_time"}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"month","value-format":"yyyy-MM","picker-options":item.pickerGraduateTime,"placeholder":"毕业年月"},on:{"focus":function($event){return _vm.handleAdmissionChange(item.admission_time, i)}},model:{value:(item.graduate_time),callback:function ($$v) {_vm.$set(item, "graduate_time", $$v)},expression:"item.graduate_time"}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"专业：","rules":{
                required: true,
                message: '请输入专业',
                trigger: 'blur',
              },"prop":"professional"}},[_c('el-input',{attrs:{"clearable":"","maxlength":"50","placeholder":"专业"},model:{value:(item.professional),callback:function ($$v) {_vm.$set(item, "professional", $$v)},expression:"item.professional"}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-row',[_c('el-col',{attrs:{"span":13}},[_c('el-form-item',{attrs:{"label":"学位：","rules":{
                    required: true,
                    message: '请选择学位',
                    trigger: 'change',
                  },"prop":"background"}},[_c('el-select',{attrs:{"placeholder":"请选择"},on:{"change":function($event){return _vm.handleSelectChange($event, i)}},model:{value:(item.background),callback:function ($$v) {_vm.$set(item, "background", $$v)},expression:"item.background"}},_vm._l((_vm.degreeOpts),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1),_c('el-col',{staticClass:"form-item",attrs:{"span":11}},[_c('el-form-item',{attrs:{"rules":{
                    required: true,
                    message: '请选择学位类型',
                    trigger: 'change',
                  },"prop":"degree_type"}},[_c('el-select',{attrs:{"placeholder":"请选择学位类型"},on:{"change":function($event){return _vm.handleSelectChange($event, i)}},model:{value:(item.degree_type),callback:function ($$v) {_vm.$set(item, "degree_type", $$v)},expression:"item.degree_type"}},_vm._l((_vm.degreeTypes),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1)],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-row',[_c('el-col',{attrs:{"span":15}},[_c('el-form-item',{attrs:{"label":"上课地点：","rules":{
                    required: true,
                    message: '请输入上课地点',
                    trigger: 'blur',
                  },"prop":"school_address.country"}},[_c('el-tooltip',{attrs:{"effect":"dark","content":"请填写上课所在的校区，对应的国家及城市","placement":"top"}},[_c('CountrySelect',{attrs:{"placeholder":"输入匹配国家及地区"},on:{"input":function($event){return _vm.handleSelect($event, i)}},model:{value:(item.school_address.country),callback:function ($$v) {_vm.$set(item.school_address, "country", $$v)},expression:"item.school_address.country"}})],1)],1)],1),_c('el-col',{attrs:{"span":9}},[(item.school_address.country.indexOf('中国') !== -1 ||
                    item.school_address.country === ''
                    )?_c('el-form-item',{staticClass:"form-item",attrs:{"prop":"school_address.area","rules":{
  required: true,
  message: '请选择城市',
  trigger: 'change',
}}},[_c('CitySelect',{staticStyle:{"width":"100%"},attrs:{"country":item.school_address.country},model:{value:(item.school_address.area),callback:function ($$v) {_vm.$set(item.school_address, "area", $$v)},expression:"item.school_address.area"}})],1):_c('el-form-item',{staticClass:"form-item",attrs:{"prop":"school_address.foreign","rules":{
                    required: true,
                    message: '请输入城市',
                    trigger: 'blur',
                  }}},[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入城市"},model:{value:(item.school_address.foreign),callback:function ($$v) {_vm.$set(item.school_address, "foreign", $$v)},expression:"item.school_address.foreign"}})],1)],1)],1)],1)],1)],1)],1),(i === _vm.ruleForm.length - 1)?_c('div',{staticClass:"add-box"},[_c('div',{staticClass:"add-btn",on:{"click":_vm.addAducation}},[_c('el-button',{attrs:{"type":"text"}},[_vm._v("+新增学历信息")])],1)]):_vm._e()])],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }