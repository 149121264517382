<!--
 * @Author: Jacob.Zhang
 * @Description: 申请人信息
-->
<template>
    <div class="user-container">
        <!-- 国籍 -->
        <Container title="国籍（请优先输入您的国籍，我们将根据您的国籍做证件的匹配）">
            <el-form ref="nationForm" :model="currentItem" :rules="nationRules" label-width="140px" class="demo-ruleForm">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="国籍/地区：" prop="nationality">
                            <CountrySelect v-model="currentItem.nationality" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="港澳通行证：" prop="is_handled">
                            <el-select v-model="currentItem.is_handled" placeholder="请选择" clearable>
                                <el-option label="办理" :value="1" />
                                <el-option label="未办理" :value="0" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </Container>
        <!-- 证件上传 -->
        <Container v-if="currentItem.nationality && currentItem.is_handled !== ''" title="证件（可通过电脑上传证件，可为您节省该页40%的信息填写时间）">
            <CertificateUpload ref="CertificateUpload"
                :nationality="currentItem.nationality" :is-handled="currentItem.is_handled"
                :certificates="currentItem.certificates" @handleInfo="getCertificate"
            />
        </Container>
        <!-- 主申请人信息 -->
        <Container title="主申请人信息">
            <el-form ref="currentItem" :model="currentItem" :rules="rules" label-width="140px" class="demo-ruleForm">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="主申姓名：" prop="username">
                            <el-input v-model="currentItem.username" placeholder="姓名请与身份证一致" clearable auto-complete="off" maxlength="50" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="曾用名：" prop="used_name">
                            <el-input v-model="currentItem.used_name" placeholder="请与户口本一致，若无则不填" clearable auto-complete="off" maxlength="50" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="婚前姓氏:" prop="surname">
                            <el-input
                                v-model="currentItem.surname"
                                placeholder="如有则填写，仅支持输入字母"
                                clearable auto-complete="off"
                                maxlength="20"
                                @input="checkSurname"
                                @blur="blurSurname"
                                />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="出生日期：" prop="birthday">
                            <el-date-picker v-model="currentItem.birthday" value-format="yyyy-MM-dd" type="date" placeholder="请选择" :picker-options="pickerOptions" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="出生城市：" :prop="`birth_place_aboard`" class="birth_city">
                            <el-radio-group v-model="currentItem.birth_place_aboard">
                                <el-radio :label="0">国内</el-radio>
                                <el-radio :label="1">国外</el-radio>
                            </el-radio-group>
                            <el-form-item v-if="currentItem.birth_place_aboard === 0" prop="birth_place.area" class="add-fields">
                                <CitySelect v-model="currentItem.birth_place.area" clearable />
                            </el-form-item>
                            <el-form-item v-if="currentItem.birth_place_aboard === 1" prop="birth_place.foreign" class="add-fields">
                                <el-input v-model="currentItem.birth_place.foreign" maxlength="100" placeholder="请输入" clearable auto-complete="off" />
                            </el-form-item>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="性别：" prop="sex">
                            <el-select v-model="currentItem.sex" placeholder="请选择" clearable>
                                <el-option label="男" :value="1" />
                                <el-option label="女" :value="2" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="婚姻状况：" prop="married">
                            <el-select v-model="currentItem.married" placeholder="请选择" clearable>
                                <el-option v-for="(item, index) in Object.values($CONFIG.MARITAL_STATUS_OPTIONS)" :key="index" :label="item.label" :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="手机号：" prop="mobile">
                            <el-input v-model.trim="currentItem.mobile" placeholder="请输入手机号" clearable auto-complete="off" maxlength="20" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="邮箱：" prop="email">
                            <el-input v-model.trim="currentItem.email" placeholder="请输入邮箱" clearable auto-complete="off" maxlength="50" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="现居住地址：" prop="address">
                            <CurrentAddress ref="CurrentAddress" v-model="currentItem.address" :is_live_oversea_year.sync="currentItem.is_live_oversea_year"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否办理过澳门身份证：" prop="has_hk_id" label-width="180px">
                            <el-radio-group v-model="currentItem.has_hk_id">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="currentItem.has_hk_id === 1" label="澳门身份证号码：" prop="hk_id_number">
                            <el-input v-model.trim="currentItem.hk_id_number" clearable auto-complete="off" maxlength="25" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </Container>
        <!-- 中国身份证 -->
        <Container title="中国身份证（可上传图片识别）">
            <CnidForm ref="CnidForm"
                :card-info="currentItem.certificates.cn_identity_card"
                :required="currentItem.nationality.includes('中国-')"
            />
        </Container>
        <!-- 港澳通行证 -->
        <Container v-if="currentItem.is_handled === 1" title="港澳通行证（可上传图片识别）">
            <HkmPass ref="HkmPass" :card-info="currentItem.certificates.hk_macao_pass" :required="currentItem.is_handled === 1" />
        </Container>
        <!-- 护照 - 是否必填取决于国籍和居住地 -->
        <Container title="护照及其他旅行证件(暂不支持上传图片识别)">
            <Passport ref="Passport"
                :card-info="currentItem.certificates.passport"
                :required="(!!currentItem.nationality && !currentItem.nationality.includes('中国-')) || (!!currentItem.address.country && !currentItem.address.country.includes('中国-'))"
            />
        </Container>
    </div>
</template>

<script>
import CnidForm from '../../../../components/CnidForm/CnidForm.vue'
import HkmPass from '../../../../components/HkmPass/HkmPass.vue';
import Passport from '../../../../components/Passport/Passport.vue';
import { postClientInfo } from '../../../../api/gifted'
export default {
    components: { CnidForm, HkmPass, Passport },
    props: {
        // 主申人信息
        currentItem: {
            type: Object,
            default() {
                return {}
            },
        },
        loading: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            nationRules: {
                nationality: [{ required: true, message: "请输入国籍/地区", trigger: "change", }],
                is_handled: [{ required: true, message: "请选择签发地", trigger: "change", }],
            },
            rules: {
                username: [{ required: true, message: "请输入姓名", trigger: "blur", }],
                birthday: [{ required: true, message: "请选择出生日期", trigger: "change", }],
                birth_place_aboard: [{ required: true, message: "请选择国内/国外", trigger: "change", }],
                'birth_place.area': [{ required: true, message: "请选择出生城市", trigger: "change", }],
                'birth_place.foreign': [{ required: true, message: "请输入地址", trigger: "blur", }],
                sex: [{ required: true, message: "请选择性别", trigger: "change", }],
                married: [{ required: true, message: "请选择婚姻状况", trigger: "change", }],
                mobile: [{ required: true, message: "请输入手机号", trigger: "blur", }],
                email: [{ required: true, trigger: "blur", validator: this.$rules.validate.email, }],
                address: [{ required: true, message: "请输入国家", trigger: "change", }],
                has_hk_id: [{ required: true, message: "请选择是否办理过澳门身份证", trigger: "change", }],
                hk_id_number: [{ required: true, trigger: "blur", validator: this.$rules.validate.macaoId }],
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },
        }
    },
    watch: {
        'currentItem.nationality'(newVal) {
            if(newVal && newVal.includes('中国-')) {
                this.$refs.Passport.clearCheck()
            } else {
                this.$refs.CnidForm.clearCheck()
            }
        },
        'currentItem.address.country'(newVal) {
            this.$refs.Passport.clearCheck()
            // 现居住国家变动 address_aboard
            if(newVal && newVal.includes('中国')) {
                this.currentItem.address_aboard = 0
            } else if(newVal && !newVal.includes('中国')) {
                this.currentItem.address_aboard = 1
            } else {
                this.currentItem.address_aboard = ""
            }
        },
    },
    methods: {
        // 过滤婚前姓氏的字段
        checkSurname(value) {
            this.currentItem.surname = value.replace(/[^\a-z.\s*\//]/ig, '').replace('/','').toUpperCase()
        },
        // 失焦后去除头尾冗余空格
        blurSurname() {
            this.currentItem.surname = this.data.surname.trim()
        },
        // 上传证件后的回调
        getCertificate(info) {
            // 图片
            this.currentItem.certificates[info.type][`img_${info.id_card_side}`] = info.url
            // 判断证件类型
            switch (info.type) {
                case 'cn_identity_card':
                    if(info.id_card_side === 'front') {
                        this.currentItem.certificates[info.type].number = info.number
                        if(!this.currentItem.nationality.includes('中国-')) return
                        // 外籍时 身份证信息不填入主申人信息
                        this.currentItem.username = info.username
                        this.currentItem.birthday = info.birthday
                        this.currentItem.sex = info.sex
                    }
                    if(info.id_card_side === 'back') {
                        this.currentItem.certificates[info.type].issue_at = info.issue_at
                        this.currentItem.certificates[info.type].issue_date_start_at = info.issue_date_start_at
                        this.currentItem.certificates[info.type].issue_date_end_at = info.issue_date_end_at
                    }
                    break;
                case 'hk_macao_pass':
                    if(info.id_card_side === 'front') {
                        this.currentItem.certificates[info.type].number = info.number
                        this.currentItem.certificates[info.type].issue_at = info.issue_at
                        this.currentItem.certificates[info.type].issue_date_start_at = info.issue_date_start_at
                        this.currentItem.certificates[info.type].issue_date_end_at = info.issue_date_end_at
                    }
                    break;
                case 'passport':
                    if(info.id_card_side === 'front') {
                        this.currentItem.certificates[info.type].number = info.number
                        this.currentItem.certificates[info.type].issue_at = info.issue_at
                        this.currentItem.certificates[info.type].issue_date_start_at = info.issue_date_start_at
                        this.currentItem.certificates[info.type].issue_date_end_at = info.issue_date_end_at
                        if(this.currentItem.nationality.includes('中国-')) return
                        // 外籍时 护照信息填入主申人信息
                        this.currentItem.username = info.username
                        this.currentItem.birthday = info.birthday
                        this.currentItem.sex = info.sex
                    }
                    break;
                default:
                    break;
            }
            console.log(info)
        },

        /**
         * @description 校验当前步骤的表单
         * @param {type} 1保存 2下一步
         */
        async checkInfo(type) {
            if(type === 1) {
                this.submit(type)
                return
            }

            // 点击的是下一步 校验成功后发送请求
            Promise.all([
                this.$refs.nationForm.validate(),
                this.$refs.currentItem.validate(),
                this.$refs.CurrentAddress.checkInfo(),
                // (this.currentItem.nationality && this.currentItem.is_handled !== '') && this.$refs.CertificateUpload.checkInfo(),
                // 证件信息
                this.$refs.CnidForm.checkInfo(),
                this.currentItem.is_handled === 1 && this.$refs.HkmPass.checkInfo(),
                this.$refs.Passport.checkInfo(),
            ])
            .then(async () => {
                console.log('可以调接口了')
                // 请求成功后 跳转
                this.submit(type)
            })
            .catch((err) => {
                console.log(err)
                this.$utils.scrollIntoError(this)
            })
        },

        async submit(type) {
            try {
                this.$emit('update:loading', true)
                const res = await postClientInfo({
                    ...this.currentItem,
                    next: type - 1, // 0保存 1下一步
                })
                if(res.code === 200) {
                    this.$message.success(type === 2 ? '提交成功' : '保存成功')
                    type === 2 ? this.$emit('next') : this.$emit('update')
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.$emit('update:loading', false)
            }
        },
    },
}
</script>

<style lang="less" scoped>
</style>
