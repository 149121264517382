<!--
 * @Author: zhai
 * @Description: 家庭背景
-->
<template>
    <div>
        <el-form ref="userForm" :model="userForm" :rules="rules" label-width="0">
            <!-- 家庭背景 -->
            <Container title="家庭背景">
                <div class="basics-box">
                    <div class="basics-title">1、至少一名直系家庭成员（已婚配偶、父母、兄弟姊妹、子女）是现居于澳门的澳门永久性居民</div>
                    <div class="background-con">
                        <el-form-item prop="background_member.has">
                            <el-radio-group v-model="userForm.background_member.has">
                                <el-radio label="Y">满足</el-radio>
                                <el-radio label="N">不满足</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item v-if="userForm.background_member.has === 'Y'" prop="background_member.info">
                            <el-select v-model="userForm.background_member.info" placeholder="请选择" clearable>
                                <el-option v-for="(item,index) in userForm.subset" :key="index" :label="item.subset_name" :value="item.id" />
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
            </Container>
        </el-form>
    </div>
</template>

<script lang="javascript">
export default {
    name: "Background",
    props: {
        userForm: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            rules: {
                "background_member.has": [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
                "background_member.info": [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
            },
        };
    },
    created() {
        console.log(this.userForm)
    },
    methods: {
        // 校验
        submitForm () {
            let that = this;
            return new Promise(function (resolve, reject) {
                that.$refs.userForm.validate(valid => {
                    if (valid) {
                        resolve(valid)
                    } else {
                        reject();
                    }
                })
            })
        }
    },
};
</script>

<style lang="less" scoped>
.basics-box {
    padding: 0 24px;
    color: #606266;
    font-size: 14px;

    .basics-title {
        margin-bottom: 9px;
        position: relative;
        padding-left: 12px;

        &::before {
            content: "*";
            position: absolute;
            left: 0;
            top: 3px;
            color: red;
        }
    }

    .basics-title.no-tip {
        padding-left: 0;

        &::before {
            content: "";
        }
    }
    .background-con {
        display: flex;
    }
}
</style>
