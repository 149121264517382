<template>
    <div class="qualification">
        <!-- 学历证书姓名及出生日期正确性 -->
        <Container title="学历证书姓名及出生日期正确性">
            <el-form :model="ruleForm" ref="ruleForm" label-position="top" label-width="140px">
                <el-form-item
                    label="1、您的所有学历及资格证书上的姓名及出生日期，是否与身份证完全一致 ？  （例如：身份证上是【张四】。学位证上是【张三】，则为不相同）"
                    prop="qualification"
                    :rules="{ required: true, message: '请选择', trigger: 'change' }"
                >
                    <el-radio-group v-model="ruleForm.qualification">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="2">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item
                    v-if="ruleForm.married != 2"
                    label="2、您配偶的大学毕业证和学位证上的姓名及出生日期，是否与身份证完全一致？"
                    prop="graduate"
                    :rules="{ required: true, message: '请选择', trigger: 'change' }"
                >
                    <el-radio-group v-model="ruleForm.graduate">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="2">否</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
        </Container>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default:() => {}
        }
    },
    data() {
        return {
            ruleForm: {
                qualification: '',
                graduate: ''
            }
        }
    },
    created() {
        if (this.data) {
            // 100为后端默认值，前端处理为空
            for(let key in this.data) {
                if (this.data[key] === 100) {
                    this.data[key] = ''
                }
            }
            this.ruleForm = this.data
        }
    },
    methods: {
        // 校验参数
        checkForm() {
            const isCheck = Promise.all([this.$refs['ruleForm'].validate()])
            return isCheck
        }
    },
}
</script>

<style lang="less" scoped>
.add-box {
    border-top: 1px solid #eee;
    padding-top: 15px;
    margin: 20px;
    text-align: center;
    .add-btn {
        border: 1px dashed #ddd;
        padding: 3px 0;
    }
}
</style>
